export const nombarText = {
    header: 'Текущие  номинации',
    team_texts: [
        ` *Для участия в данной номинации необходимо состоять в команде.<br>Команды должны быть сформированы до`,
        `Ты уже состоишь в команде для участия в данной номинации!`,
        `Регистрация команд закрыта`
    ],
    not_team_texts: [
        ' *В рамках отборочного этапа для данной номинации есть задача. Нажми "Приcтупить к задаче", если тебя заинтересовало участие в номинации.',
        '*Участие в данной номинации доступно без отборочного этапа. Нажми "Я участвую!", если готов продемонстрировать свои навыки.',
        'Также ты можешь решить задачу отборочного этапа, это даст тебе дополнительные очки.',
        'Ты записан на участие в данной номинации!',
        'Запись на участие в номинации закрыта'
    ],
    task_active: 'Можно приступать к выполнению!'
}