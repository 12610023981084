const header = 'НОВЫЙ ШАГ: НАЧАЛО ТВОЕГО ПУТИ<br>В ЦИФРОВЫХ ПРОФЕССИЯХ';

export const regText = {
    hello_text1: 'Привет! Это верный адрес, отсюда начинается твоё будущее в профессиональных информационных технологиях.',
    hello_text2: 'Давай знакомиться:',
    where_you_study: 'Где ты учишься?',
    button_to_hand_stduy: 'Добавить вручную',
    button_to_auto_study: 'Вернуться к списку',
    warning_about_fake_study: 'Если будет указано несуществующее учебное заведение, то мы оставляем за собой право удалить зарегистрированный аккаунт.',
    run_to_finaly: 'Почти готово',
    about_auto_reg: 'Возникли проблемы при регистрации? Заполни все необходимые поля, и мы сами зарегистрируем тебя!',
    // label_about_personal_data: 'Согласен на обработку своих персональных данных в соответствии с Федеральным законом Российской Федерации от 27 июля 2006 года № 152-ФЗ "О персональных данных", а также информирование для участия в цифровых студенческих мероприятиях с использованием платформы "Новый шаг".',
    personal_data_about: 'Подтверждаю свое согласие с условиями ',
    personal_data_link: 'обработки персональных данных.',
    error_fill_all_fields: 'Заполни все поля!',
    auto_reg_request_ok: `Заявка на регистрацию отправлена. Мы зарегистрируем тебя в течении недели. На почту придет 2 письма: одно с ссылкой для подтверждения аккаунта, второе с паролем для входа.`,
    auto_reg_request_fail: `Не удалось отправить заявку на регистрацию. Возможны неполадки на сервере, попробуйте позднее.`,
    dont_confirm_pass: 'Не верно повторен пароль',
    edu_report_ok: `Заявка на добавление учебного заведения отправлена. После модерации твое учебное заведение отобразиться в личном кабинете.`,
    edu_report_fail: `Не удалось отправить заявку на добавление учебного заведения. После авторизации перейди на форму обратной связи и оставь данные о своем учебном заведении там.`,
    register_ok: `Регистрация аккаунта прошла успешно. Тебе на почту пришло письмо с ссылкой для активации аккаунта. После этого ты сможешь авторизоваться.`,
    header_reg_fail: 'Необходимо исправить следующие ошибки:',
    dont_find_inst1: 'Учебных заведений с данным типом нет в нашей базе данных. <br> Нажми "Добавить вручную".',
    dont_find_inst2: 'Учебных заведений с данным типом нет в нашей базе данных. <br> Перейди на форму обратной связи и оставь данные о своем учебном заведении.',
    password_ruls: `Длина пароля не менее 8 символов
Пароль не должен быть слишком распространенным
Пароль должен содержать как минимум одну: 
    цифру
    букву в верхнем регистре
    букву в нижнем регистре`,
    password_ruls_html: `<ul class="text-blue"><li>Длина пароля не менее 8 символов</li><li>Пароль не должен быть слишком распространенным</li><li>Пароль должен содержать как минимум одну: <ul><li>цифру</li><li>букву в верхнем регистре</li><li>букву в нижнем регистре</li></ul></li></ul>`,
    backStep1: 'Назад',
    backStep2: 'Ручная регистрация',
    nextStep1: 'Следующий шаг',
    nextStep2: 'Вернуться к стандартной регистрации',
    about_backStep2: 'Возникли проблемы при регистрации?',
    login: 'Войти',
    about_login: 'Уже есть аккаунт?',
    button_reg: 'Зарегистрироваться',
    header: header,
};


export const loginText = {
    forgot_pass_button: 'Забыл пароль',
    login_button: 'Войти',
    button_to_reg: 'Зарегистрироваться',
    button_to_reg_about: 'Еще нет аккаунта?',
    button_to_question: 'тут',
    button_to_question_about: 'Есть вопрос? Ты можешь задать его ',
    email_contact_about: 'Почта для связи: ',
    header: header,
    email_contact: 'nwstep@internet.ru',
    login_success: 'Авторизация прошла успешно',
    login_cancel: 'Авторизация отменена'
};

export const forgotPasswordText = {
    change_pass_about: 'Введи почтовый адрес аккаунта, на него придет временный пароль.',
    change_pass_button: 'Отправить',
    change_pass_susses: `Новый пароль выcлан на почту. В личном кабинете можно изменить пароль.`,
    header: header,
}

export const callBackText = {
    call_back_about: 'Напиши свой вопрос, предложение или проблему, с которой ты столкнулся при использовании платформы',
    call_back_button: 'Отправить',
    call_back_susses: 'Спасибо за обратную связь. Пришлем ответ по почте.',
    call_back_fail: `Отправка не удалась. Возможны неполадки на сервере, попробуй позднее.`,
    header: header,
}