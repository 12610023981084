
const URL = 'nwstep.ru';
// const URL = 'b159-176-114-215-204.ngrok-free.app';

const config = {
    apiURL: `/api/`,
    //apiURL: `https://${URL}/api/`,
    wsURL: `wss://${URL}/ws/`
}
//nwstep.ru
export default config;