<template>
    <div class="modal" @click="closeModal">
      <div class="modal-content"  @click.stop="">
        <div class="d-flex justify-content-end">
          <span class="close" @click="closeModal">&times;</span>
        </div>
        <div class="modal__html mb-3 text-center mx-auto col-md-7" v-html="modal.content"></div>
        <form @submit.prevent="joinTeam" novalidate class="m-auto col-md-7">

          <div class="mb-3 input-box">
            <label for="team_code" class="form-label">Код приглашения</label>
            <input v-model="team_code" type="text" class="input"
                    id="team_code"
                    v-bind:class="{ 'border-danger': !(isCorrect.team_code)}" style="width: 100%;">
          </div>

          <div class="d-flex justify-content-center mb-2">
            <button type="submit" class="btn btn-primary">Присоединиться к команде</button>
          </div>
        </form>
      </div>
    </div>
</template>
<script>

import { validateMethod } from '../../methods/validate.method';

import { teamService, eventService, eventUserService } from '../../services/event/event.service';

export default {
    computed: {
        modal() {
            return this.$store.state.modalJoinTeam;
        }
    },
  data() {
    return {
        team_code: '',
        isCorrect: {
            team_code: true
        }
    }
  },
  methods: {
    closeModal() {
        this.$store.dispatch('modalJoinTeam/closeModal');
        this.clrearModal();
    },
    teamCodeChanged() {
        this.isCorrect.team_code = !validateMethod.checkIsEmptyStr(this.team_code)
        return this.isCorrect.team_code;
    },
    async joinTeam() {
        if(!this.teamCodeChanged()) return;

        try {
          const response = await teamService.invitingTeam(this.team_code);
          this.$store.dispatch('alert/sendMessage', { message: response.ok, type: 'Success' })
        } catch (error) {
          let message = error.response.data;
          if(error.response.status !== 500)  message = message.error;
          this.$store.dispatch('alert/sendMessage', { message: message, type: 'Danger' });
          this.clrearModal();
          return;
        }

        const event_id = this.$store.state.modalJoinTeam.event_id;

        try {
          (await eventService.getNominationsFromEvent(event_id).catch(() => [])).forEach(el => {
            eventUserService.postUserNomination(el.id)
          })
        } catch (error) {
          console.log(error);
        }

        this.closeModal();
        this.$router.push("/LK/my-teams");
        
    },
    clrearModal(){
        this.team_code = ''
        this.isCorrect.team_code = true;
    }
  }
}
</script>

<style>
   
</style>