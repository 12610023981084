export const footerText = {
    tg_about: 'Телеграм-канал проекта: ',
    tg_link: '@tverchamp',
    policy_presonal_data: 'Политика обработки персональных данных',
    link_to_calback_form: 'Форма обратной связи',
    email: 'nwstep@internet.ru',
    email_about: 'Почта для связи: ',
}

export const newsText = {
    header: 'Новости',
    load_more: 'Загрузить ещё',
    all_news_button: 'Все новости ->',
    dont_get_news: 'Не удалось получить новости'
}